import React from "react"
import { Col } from "reactstrap"
import PropTypes from "prop-types"
import quotationMarkImage from "assets/images/landing/quotation-mark.png"

const TestimonialCard = ({ image, text, author }) => {
  return (
    <Col xs={12} lg={4}>
      <img src={image} className="w-100 rounded-4" alt="Testimonial" />
      <div className="position-relative mt-20 mt-lg-25 fs-4">
        <img src={quotationMarkImage} className="position-absolute top-0 start-0 w-25" style={{ maxWidth: "68px" }} alt="Quotation Mark" />
        <p className="pt-25 pt-lg-25">{text}</p>
        <span className="text-primary-second fw-semibold">{author}</span>
      </div>
    </Col>
  )
}

TestimonialCard.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired
}

export default TestimonialCard
