import { Island } from "./Island"
import { DivingMask } from "./DivingMask"
import { Training } from "./Training"
import { FishingBoat } from "./FishingBoat"
import { PaperMap } from "./PaperMap"
import { Canoeing } from "./Canoeing"
import { Fish } from "./Fish"
import { RopeSkipping } from "./RopeSkipping"
import { Yacht } from "./Yacht"
import { Balloon } from "./Balloon"
import { SurfingBoard } from "./SurfingBoard"
import { Surfing } from "./Surfing"
import { Paddling } from "./Paddeling"
import { Parachute } from "./Parachute"
import { HorseRiding } from "./HorseRiding"
import { WaterSkiing } from "./WaterSkiing"
import { Chaise } from "./Chaise"
import { GolfMobile } from "./GolfMobile"
import { Tent } from "./Tent"
import { Dock } from "./Dock"
import { Booking } from "./Booking"
import { Gallery } from "./Gallery"
import { Dashboard } from "./Dashboard"
import { Calendar } from "./Calendar"
import { Messages } from "./Messages"
import { Marketing } from "./Marketing"
import { Finances } from "./Finances"
import { Boat } from "./Boat"
import { Notification } from "./Notification"
import { Weather } from "./Weather"
import { Experiences } from "./Experiences"
import { Support } from "./Support"
import { FAQ } from "./FAQ"
import { QR } from "./QR"
import { EBike } from "./EBike"
import { Scooter } from "./Scooter"
import { Settings } from "./Settings"
import { User } from "./User"
import { Password } from "./Password"
import { Brush } from "./Brush"
import { Card } from "./Card"
import { License } from "./License"
import { Investment } from "./Investment"
import { Menu } from "./Menu"
import { Profit } from "./Profit"
import { Fuel } from "./Fuel"
import { Maintenance } from "./Maintenance"
import { Bag } from "./Bag"
import { Membership } from "./Membership"
import { Insurance } from "./Insurance"
import { Accomodation } from "./Accomodation"
import { Other } from "./Other"
import { Advertising } from "./Advertising"
import { Food } from "./Food"
import { Staff } from "./Staff"

const icons = {
  island: Island,
  divingMask: DivingMask,
  training: Training,
  fishingBoat: FishingBoat,
  paperMap: PaperMap,
  canoeing: Canoeing,
  fish: Fish,
  ropeSkipping: RopeSkipping,
  yacht: Yacht,
  balloon: Balloon,
  surfingBoard: SurfingBoard,
  surfing: Surfing,
  paddling: Paddling,
  parachute: Parachute,
  horseRiding: HorseRiding,
  waterSkiing: WaterSkiing,
  chaise: Chaise,
  golfMobile: GolfMobile,
  tent: Tent,
  dock: Dock,
  booking: Booking,
  gallery: Gallery,
  dashboard: Dashboard,
  calendar: Calendar,
  messages: Messages,
  marketing: Marketing,
  finances: Finances,
  boat: Boat,
  notification: Notification,
  weather: Weather,
  experiences: Experiences,
  support: Support,
  faq: FAQ,
  qr: QR,
  eBike: EBike,
  scooter: Scooter,
  settings: Settings,
  user: User,
  password: Password,
  brush: Brush,
  card: Card,
  license: License,
  investment: Investment,
  menu: Menu,
  profit: Profit,
  fuel: Fuel,
  maintenance: Maintenance,
  bag: Bag,
  membership: Membership,
  insurance: Insurance,
  accomodation: Accomodation,
  other: Other,
  advertising: Advertising,
  food: Food,
  staff: Staff
}

export const DSIcon = ({ name, ...props }) => {
  const Icon = icons[name]
  return <Icon {...props} />
}
