import i18n from "i18n"
import { routes } from "router/routes"

const errorMessage = {
  "user.attributes.email.does_not_exist": {
    text: i18n.t("form_validation.email_not_exists"),
    linkText: i18n.t("sign_up.register"),
    href: routes?.signUpOptions()
  },
  "user.attributes.password.incorrect": {
    text: i18n.t("form_validation.incorrect_password")
  },
  "user.attributes.phone_number.invalid": {
    text: i18n.t("form_validation.phone_number_invalid")
  },
  "user.attributes.phone_number.incorrect": {
    text: i18n.t("form_validation.phone_not_exists"),
    linkText: i18n.t("sign_up.register"),
    href: routes?.signUpOptions()
  },
  "user.attributes.pin_code.incorrect": {
    text: i18n.t("form_validation.pin_code_incorrect")
  },
  "user.attributes.pin_code.expired": {
    text: i18n.t("form_validation.pin_code_expired")
  },
  "user.attributes.email.taken": {
    text: i18n.t("form_validation.email_already_exists"),
    linkText: i18n.t("sign_in.log_in"),
    href: routes?.signInV2()
  },
  "user.attributes.recaptcha.incorrect": {
    text: i18n.t("form_validation.incorrect_recaptcha")
  },
  "session.errors.user.locked": {
    text: i18n.t("sign_up.user_locked")
  },
  "has already been taken": {
    text: i18n.t("form_validation.phone_number_already_exists")
  },
  "Please confirm your email first": {
    text: i18n.t("sign_in.confirm_email_first")
  }
}

export default errorMessage
