import React from "react"
import { Drawer, Button, Tabs, Col } from "antd"
import { X } from "react-feather"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

// modules
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
// components
import { PreviewHeader } from "ui/PersonalInfo/ProfilePreview/PreviewHeader"
import { PreviewGallery } from "ui/PersonalInfo/ProfilePreview/PreviewGallery"
// UI
import { Title } from "ui/Typography"
// assets
import placeholder from "assets/imagesV2/placeholder.png"

const placeholderPhotos = Array.from({ length: 5 }).map((_, i) => ({
  url: placeholder,
  id: i
}))

export const ProfilePreview = ({ show, guide, onClose, tab }) => {
  const { t } = useTranslation()

  const tabItems = [
    { label: t("trips.title"), key: "trips" },
    { label: t("global.about"), key: "about" },
    { label: t("location.title"), key: "location" },
    { label: t("boats.title"), key: "boats" },
    { label: t("posts.title"), key: "feeder" },
    { label: t("reviews.title"), key: "reviews" }
  ]

  return (
    <Drawer
      open={show}
      placement="bottom"
      closeIcon={null}
      title={
        <Title level={4} className="m-0">
          {t("global.preview")}
        </Title>
      }
      height="100%"
      extra={<Button icon={<X />} onClick={onClose} type="text" />}
    >
      <Col span={24} xl={22} xxl={18} className="p-sm mx-auto">
        <PreviewHeader
          imageSrc={guide.avatar}
          name={guide.display_name}
          rating={guide.rating}
          ratingCount={guide.rating_count}
          address={guide.display_location}
        />
        <Tabs activeKey={tab} mode="horizontal" items={tabItems} disable className="fw-500" />
        <div className="flex flex-1">
          <DefaultErrorBoundary>
            <PreviewGallery name={guide.display_name} photos={guide.photos?.length ? guide.photos : placeholderPhotos} />
          </DefaultErrorBoundary>
        </div>
        <section className="pre-wrap mt-l">
          <Title level={3}>{t("global.about")}</Title>
          {guide.description}
        </section>
      </Col>
    </Drawer>
  )
}

ProfilePreview.propTypes = {
  show: PropTypes.bool,
  guide: PropTypes.shape({
    avatar: PropTypes.string,
    display_name: PropTypes.string,
    rating: PropTypes.number,
    rating_count: PropTypes.number,
    display_location: PropTypes.string,
    photos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        placeholder_url: PropTypes.string
      })
    )
  }),
  onClose: PropTypes.func,
  tab: PropTypes.oneOf(["trips", "about", "location", "boats", "posts", "reviews"])
}

export default withDefaultErrorBoundary(ProfilePreview)
