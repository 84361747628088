import React, { useState, useRef, useEffect } from "react"
import { Form, Input, Button, Checkbox, Flex } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Title, Text } from "ui/Typography"
import { CustomInput, PhoneInput } from "ui/Input"
import { spacings } from "themes/variables"
import { CustomForm } from "ui/Form"
import { signUp } from "store/auth"
import { TermsAndConditions } from "./TermsAndConditions"
import { PrivacyPolicy } from "./PrivacyPolicy"
import { routes } from "router"
import ReCAPTCHA from "react-google-recaptcha"
import { Modal } from "ui/Modal"
import { CustomInputError } from "ui/CustomInputError"
import useFormErrors from "hooks/useAntdFormErrors"

export const SignUp = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading } = useSelector((state) => state.auth)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [form] = Form.useForm()
  const recaptchaRef = useRef()

  const showModal = (type) => {
    setModalType(type)
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setModalType(null)
    setIsModalOpen(false)
  }
  const { errors } = useFormErrors(form)

  const onFinish = (user) => {
    user.is_sportsman = true
    user.password_confirmation = user.password
    user.role = "user"
    user.redirect_url = "/auth/sign-up/confirm-email"

    dispatch(signUp(user)).then((res) => {
      navigate(routes.confirmEmail({}, { email: user.email }))
    })
  }

  useEffect(() => {
    if (!loading && recaptchaRef.current) {
      recaptchaRef.current.reset()
      form.setFieldValue("captcha_token", "")
    }
  }, [loading])

  return (
    <>
      <Title level={2} className="justify-center mb-3xl">
        {t("sign_up.register")}
      </Title>
      <CustomForm form={form} name="sign-up-email" layout="vertical" onFinish={onFinish}>
        <Flex gap={spacings.S}>
          <Form.Item
            label={t("sign_up.first_name")}
            name="first_name"
            required={true}
            rules={[{ required: true, message: t("form_validation.cannot_be_blank") }]}
            className="flex-grow-1"
          >
            <CustomInput placeholder={t("sign_up.name")} />
          </Form.Item>
          <Form.Item
            label={t("sign_up.last_name")}
            name="last_name"
            required={true}
            rules={[{ required: true, message: t("form_validation.cannot_be_blank") }]}
            className="flex-grow-1"
          >
            <CustomInput placeholder={t("sign_up.last_name")} />
          </Form.Item>
        </Flex>
        <Form.Item
          label={t("sign_up.email")}
          name="email"
          required={true}
          rules={[
            { required: true, message: t("form_validation.cannot_be_blank") },
            { type: "email", message: t("form_validation.invalid_email") }
          ]}
          help={!!errors?.email ? <CustomInputError errorKey={errors.email} /> : null}
        >
          <CustomInput placeholder={t("sign_up.email_placeholder")} />
        </Form.Item>
        <Form.Item
          label={t("sign_up.phone_number")}
          name="phone-number"
          help={!!errors?.phone_number ? <CustomInputError errorKey={errors.phone_number} /> : null}
        >
          <PhoneInput />
        </Form.Item>
        <Form.Item
          label={t("sign_up.password")}
          name="password"
          required={true}
          rules={[
            { required: true, message: t("form_validation.cannot_be_blank") },
            { min: 8, message: t("form_validation.password_length") }
          ]}
        >
          <Input.Password placeholder={t("sign_up.password_placeholder")} />
        </Form.Item>
        <Form.Item
          name="captcha_token"
          help={!!errors?.recaptcha ? <CustomInputError errorKey="user.attributes.recaptcha.incorrect" /> : null}
        >
          <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY} />
        </Form.Item>
        <Form.Item
          name="agreeCheck"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error(t("form_validation.accept_terms_conditions"))))
            }
          ]}
        >
          <Checkbox>
            <Text type="secondary" variant="body">
              {t("sign_up.agree_terms")}
            </Text>
            <Text className="ml-xs" link>
              <Link onClick={() => showModal("TermsAndConditions")}>{t("sign_up.terms_conditions")}</Link>
            </Text>
            <Text className="ml-xs" link>
              &
            </Text>
            <Text className="ml-xs" link>
              <Link onClick={() => showModal("PrivacyPolicy")}>{t("sign_up.privacy_policy")}</Link>
            </Text>
          </Checkbox>
        </Form.Item>

        <Form.Item help={!!errors?.base ? <CustomInputError errorKey={errors.base} /> : null}>
          <Button className="mt-xs mb-xs" type="primary" htmlType="submit" block size="large" disabled={loading}>
            {t("sign_up.register")}
          </Button>
        </Form.Item>
      </CustomForm>
      <Flex justify="center">
        <Text type="secondary" variant="body">
          {t("sign_up.already_have_account")}
        </Text>
        <Text className="ml-xs" link>
          <Link to={routes.signInV2()}>{t("sign_in.log_in")}</Link>
        </Text>
      </Flex>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={1022}
        style={{
          top: 40
        }}
      >
        {modalType === "TermsAndConditions" ? <TermsAndConditions /> : <PrivacyPolicy />}
      </Modal>
    </>
  )
}
