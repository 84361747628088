import React, { useState } from "react"
import { Button, Col, Flex, Form, Row } from "antd"
import { serialize } from "object-to-formdata"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Play } from "react-feather"

// helpers
import { compressFile } from "helpers/compress"
// hooks
import useFormErrors from "hooks/useAntdFormErrors"
// layouts
import { ContentActionButtons } from "layoutsV2/pro/PersonalInfoLayout/ContentActionButtons"
import { ContentHeader } from "layoutsV2/pro/PersonalInfoLayout/ContentHeader"
// store
import { guideSelector, updateGuide, updateUser } from "store/user"
// themes
import { spacings } from "themes/variables"
// ui
import { CustomForm } from "ui/Form"
import { CustomInput, TextArea } from "ui/Input"
// components
import { AddAvatar } from "./AddAvatar"
import { ProfilePreview } from "ui/PersonalInfo/ProfilePreview"

export const ProfileInformation = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id, display_name, company_name, website, description, ...guide } = useSelector(guideSelector)
  const { avatar, ...guide2 } = useSelector((store) => store.user)
  const [showPreview, setShowPreview] = useState(false)

  const onFinish = (formData, navigateTo) => {
    const serializedFormData = serialize({ guide: formData }, { indices: true })
    dispatch(updateGuide(id, serializedFormData)).then(() => navigateTo())
  }

  const handleImageChange = async (e) => {
    const avatar = await compressFile(e.target.files[0])
    const formData = serialize({ avatar })
    if (avatar) dispatch(updateUser(formData))
  }

  const handlePreviewToggle = () => setShowPreview((prev) => !prev)

  useFormErrors(form)

  return (
    <>
      <Row>
        <Col lg={16} md={24} xs={24} span={16}>
          <Row justify="space-between" align="middle" gutter={spacings.XS}>
            <ContentHeader title="Profile Information" />
            <Button color="primary" size="large" variant="outlined" onClick={handlePreviewToggle} icon={<Play size={16} />}>
              {t("global.preview")}
            </Button>
          </Row>
          <CustomForm
            form={form}
            name="profile-information"
            layout="vertical"
            initialValues={{
              display_name,
              company_name,
              website,
              description
            }}
          >
            <Form.Item
              name="avatarPhoto"
              label={t("guide.profile_image")}
              required={true}
              rules={[{ required: true, message: t("form_validation.cannot_be_blank") }]}
              className="mb-4xl"
            >
              <AddAvatar avatar={avatar} handleImageChange={handleImageChange} />
            </Form.Item>
            <Flex gap={spacings.S}>
              <Form.Item
                label={t("guide.display_name")}
                name="display_name"
                required={true}
                rules={[{ required: true, message: t("form_validation.cannot_be_blank") }]}
                className="flex-grow-1"
              >
                <CustomInput placeholder={t("guide.display_name")} />
              </Form.Item>
              <Form.Item label={t("guide.company_name")} name="company_name" className="flex-grow-1">
                <CustomInput placeholder={t("guide.company_name")} />
              </Form.Item>
            </Flex>
            <Form.Item label={t("guide.website")} name="website" className="flex-grow-1">
              <CustomInput placeholder="https://" />
            </Form.Item>
            <Form.Item label={t("guide.description")} name="description" className="flex-grow-1">
              <TextArea placeholder={t("guide.description_placeholder")} style={{ height: 147 }} />
            </Form.Item>
          </CustomForm>
          <ContentActionButtons form={form} onFinish={onFinish} />
        </Col>
      </Row>
      <ProfilePreview
        show={showPreview}
        tab="about"
        guide={{
          ...guide,
          avatar,
          display_name: form.getFieldValue("display_name"),
          description: form.getFieldValue("description")
        }}
        onClose={handlePreviewToggle}
      />
    </>
  )
}
