import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper/modules"
import { ChevronLeft, ChevronRight } from "react-feather"

// styles
import styles from "./GallerySlider.module.css"

const THUMBNAILS_SCALE = 120

export const GallerySlider = ({ photos = [], preview = true, activeSlide, ratio = "16x9" }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const slideLeftRef = useRef(null)
  const slideRightRef = useRef(null)
  const swiperRef = useRef(null)

  useEffect(() => {
    if (activeSlide !== null && swiperRef.current) {
      swiperRef.current.slideTo(activeSlide)
    }
  }, [activeSlide])

  const onInit = (swiper) => {
    swiper.params.navigation.prevEl = slideLeftRef.current
    swiper.params.navigation.nextEl = slideRightRef.current
    swiper.navigation.init()
    swiper.navigation.update()
    swiperRef.current = swiper
  }

  const renderImage = (photo) => (
    <LazyLoadImage
      src={preview && photo.preview_url ? photo.preview_url : photo.url}
      alt=""
      wrapperClassName="w-full h-full"
      className="object-cover w-full h-full rounded"
      effect="blur"
      placeholderSrc={photo.placeholder_url || null}
    />
  )

  return (
    <div className="flex-column flex-1 justify-between">
      <Swiper
        style={{
          height: `calc(100vh - ${THUMBNAILS_SCALE * 2.25}px)`,
          width: "95%"
        }}
        className="m-auto"
        onInit={onInit}
        spaceBetween={10}
        slidesPerView={1}
        centeredSlides={true}
        navigation
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs, Pagination]}
        pagination={{ horizontalClass: "bottom-0" }}
      >
        {photos.map((photo, index) => (
          <SwiperSlide key={photo.id} index={index} className={`w-full h-auto rounded flex justify-center ratio ratio-${ratio}`}>
            {renderImage(photo)}
          </SwiperSlide>
        ))}
        {photos.length ? (
          <>
            <div className="swiper-button-prev fixed top-0 left-0 m-0 h-full w-auto" ref={slideLeftRef}>
              <div style={{ width: 40, height: 40 }}>
                <ChevronLeft color="gray" />
              </div>
            </div>
            <div className="swiper-button-next fixed top-0 right-0 m-0 h-full w-auto" ref={slideRightRef}>
              <div style={{ width: 40, height: 40 }}>
                <ChevronRight color="gray" />
              </div>
            </div>
          </>
        ) : null}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        freeMode={true}
        slidesPerView={photos.length < 10 ? photos.length : 10}
        modules={[FreeMode, Navigation, Thumbs]}
        className={styles.thumbnails}
        style={{
          height: THUMBNAILS_SCALE
        }}
      >
        {photos.map((photo, index) => (
          <SwiperSlide
            key={photo.id}
            style={{
              minWidth: THUMBNAILS_SCALE
            }}
            index={index}
            className="cursor-pointer rounded"
          >
            {renderImage(photo)}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

GallerySlider.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      preview_url: PropTypes.string,
      placeholder_url: PropTypes.string
    })
  ).isRequired,
  preview: PropTypes.bool,
  ratio: PropTypes.string
}
