import React from "react"
import { useMatch } from "react-router-dom"
import { routes } from "router/routes"
import { useSelector } from "react-redux"
import styles from "./UnactiveProPage.module.css"

export default function UnactiveProPage({ children }) {
  const { guide, role } = useSelector((state) => state.user)
  const isSettingsPage = useMatch({ path: routes.guideSettingsRootPath(), end: false })

  if (role === "user") return children
  if (guide?.active || isSettingsPage) return children
  return (
    <div className={styles.wrapper}>
      <h3>Your account is currently under review.</h3>
      <p>
        Once approved, you will get access to all the features of the Digital Sportsman Platform.
        <br />
        You should receive the approval email very shortly.
      </p>
    </div>
  )
}
