import React, { memo, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import { routes } from "router/routes"
import { Badge, DropdownToggle } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import { useCable } from "components/Cable"
import Avatar from "components/common/Avatar"

import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import { createScorllSaver } from "helpers/scroll"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { signOut } from "store/auth"
import { receivedWSConversation } from "store/conversations"

import { channels } from "constants/cable"
import { metadataSelector } from "store/selectors"
import { isGuideCompletedSelector, isGuideSelector } from "store/user"

const scrollSaver = createScorllSaver()

UserMenu.propTypes = {
  className: PropTypes.string
}

UserMenu.defaultProps = {
  className: ""
}

function UserMenu({ className }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()
  const back_url = useLocation().pathname
  const cable = useCable()
  const chanel = useRef(null)
  const loggedIn = useSelector((state) => state.auth.loggedIn)
  const user = useSelector((store) => store.user)
  const { guide } = user
  const isGuide = useSelector(isGuideSelector)
  const isGuideWizardCompleted = useSelector(isGuideCompletedSelector)
  // usePresenceStatus(user, updateUserStatus)

  const metadata = useSelector(metadataSelector("conversations"))
  const totalUnviewed = metadata.total_unviewed_messages
  const messagesBox = document.getElementById("message-list")
  const messagesBoxScrollSaver = useRef(null)

  const signOutHandler = confirmModal(
    { title: "Are you sure you want to sign out?", color: "danger", submitText: t("global.confirm") },
    () => dispatch(signOut())
  )

  useEffect(() => {
    if (!messagesBox) return
    const [saveScroll, scrollToSaved, scrollTo] = scrollSaver(messagesBox)
    messagesBoxScrollSaver.current = { saveScroll, scrollToSaved, scrollTo }
  }, [messagesBox])

  const beforeAction = (action) => {
    if (action.type === receivedWSConversation.type && messagesBoxScrollSaver.current) messagesBoxScrollSaver.current.saveScroll()
  }

  const afterAction = (action, response) => {
    if (action.type === receivedWSConversation.type && messagesBoxScrollSaver.current)
      messagesBoxScrollSaver.current.scrollToSaved((savedScrolls) => (savedScrolls.bottom === 0 ? "bottom" : "top"))
  }

  const receivedHandler = (beforeAction, afterAction) => (action) => {
    beforeAction?.(action)
    const dispatchResult = dispatch(action)

    if (dispatchResult instanceof Promise) dispatchResult.then((res) => afterAction(action, res))
    else afterAction(action)
  }

  useEffect(() => {
    console.log("WS_ON", process.env.REACT_APP_WS_ON)
    if (!cable || [false, "false"].includes(process.env.REACT_APP_WS_ON)) return
    chanel.current = cable.subscriptions.create({ channel: channels.rootChannel }, { received: receivedHandler(beforeAction, afterAction) })
    return () => chanel.current?.unsubscribe()
  }, [cable]) //eslint-disable-line

  const classes = [""]
  if (className) classes.push(className)

  const scrollToRequestDemo = () => {
    const demoRequestEl = document.querySelector("#demo-request")
    if (demoRequestEl) demoRequestEl.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Dropdown
      direction="down"
      container="body"
      toggleButton={
        <DropdownToggle className="menu-btn hstack gap-2 m-0 p-0.5 ps-3 rounded-pill bg-light border-gray-lightest text-dark">
          <div className="menu-btn__burger">
            <span>Menu</span>
          </div>
          <Avatar user={user} size={32} />
          {!isGuide && totalUnviewed > 0 && (
            <Badge
              color="white"
              className="d-block position-absolute top-auto bottom-100 start-0 mb-n2 text-primary-second text-center fw-normal lh-1 border border-primary-second"
              style={{ minWidth: 17 }}
            >
              {totalUnviewed}
            </Badge>
          )}
        </DropdownToggle>
      }
      className={classes.join(" ")}
      offset={[12, 9]}
      end
    >
      {loggedIn ? (
        <>
          {isGuide ? (
            <>
              <DropdownItem to={oldRoutes.publicGuidePath({ slug: guide.slug }, { back_url })} state={{ scrollToTop: true }}>
                {t("global.guide_preview")}
              </DropdownItem>
              {/* <DropdownItem to={oldRoutes.guideWizardRootPath()} state={{ scrollToTop: true }}>
                {t("global.guide_account")}
              </DropdownItem> */}
              <hr className="dropdown-divider" />
            </>
          ) : (
            <>
              <DropdownItem to={oldRoutes.clientMessagesPath()} state={{ scrollToTop: true }}>
                {t("global.messages")}
                {totalUnviewed > 0 && (
                  <Badge
                    color="white"
                    className="d-inline-block text-primary-second text-center fw-normal lh-1 border border-primary-second ms-2"
                    style={{ minWidth: 17 }}
                  >
                    {totalUnviewed}
                  </Badge>
                )}
              </DropdownItem>
              <DropdownItem to={oldRoutes.clientFavoritesPath()} state={{ scrollToTop: true }}>
                {t("global.favorites")}
              </DropdownItem>
              <DropdownItem to={oldRoutes.clientBookingsPath()} state={{ scrollToTop: true }}>
                {t("global.my_trips")}
              </DropdownItem>
              <hr className="dropdown-divider" />
            </>
          )}
          <DropdownItem
            to={
              isGuide
                ? isGuideWizardCompleted
                  ? oldRoutes.guideSettingsRootPath()
                  : oldRoutes.guideWizardRootPath()
                : oldRoutes.clientSettingsRootPath()
            }
            state={{ scrollToTop: true }}
          >
            {t("global.my_account")}
          </DropdownItem>
          <DropdownItem onClick={signOutHandler}>{t("global.sign_out")}</DropdownItem>
        </>
      ) : (
        <>
          <DropdownItem to={oldRoutes.becomeAGuidePath()} state={{ scrollToTop: true }}>
            Become a Guide
          </DropdownItem>
          {/* <DropdownItem to={oldRoutes.signUpPath()} state={{ scrollToTop: true }}>
            {t("global.sign_up")}
          </DropdownItem> */}
          <hr className="dropdown-divider" />
          {/* <DropdownItem to={oldRoutes.becomeAGuidePath()} state={{ scrollToTop: true }}>
            {t("global.become_a_guide")}
          </DropdownItem> */}
          <DropdownItem state={{ scrollToTop: true }} onClick={scrollToRequestDemo}>
            See a Demo
          </DropdownItem>
          <hr className="dropdown-divider" />
          <DropdownItem to={routes.signInV2()} state={{ scrollToTop: true }}>
            {t("global.sign_in")}
          </DropdownItem>
          <hr className="dropdown-divider" />
        </>
      )}
      <DropdownItem to={oldRoutes.faqPath()} state={{ scrollToTop: true }}>
        {t("global.faq")}
      </DropdownItem>
    </Dropdown>
  )
}

export default memo(UserMenu)
