import React from "react"
import { Outlet } from "react-router-dom"
import Navigation from "components/guide/settings/Navigation"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useSelector } from "react-redux"

function SettingsLayout() {
  const { id } = useSelector((store) => store.user)

  return id ? (
    <div className="px-50 pb-20">
      <Navigation />
      <DefaultErrorBoundary>
        <Outlet />
      </DefaultErrorBoundary>
    </div>
  ) : null
}

export default withDefaultErrorBoundary(SettingsLayout)
