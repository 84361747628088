import React from "react"
import PropTypes from "prop-types"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Row, Col } from "antd"
import classNames from "classnames"

// styles
import styles from "./Gallery.module.css"

const Gallery = ({ photos = [], onImageClick }) => {
  if (photos.length === 0) return null

  const renderImage = (photo, index) => (
    <LazyLoadImage
      src={photo?.url ?? ""}
      alt=""
      className="object-cover w-full h-full rounded"
      wrapperClassName={classNames("w-full h-full", onImageClick && "cursor-pointer")}
      effect="blur"
      placeholderSrc={photo?.placeholder_url}
      onClick={() => onImageClick?.(index)}
    />
  )

  const gutter = [0, 0]

  // for photos less than 5, show them in a row with equal width
  if (photos.length < 5) {
    return (
      <Row gutter={gutter}>
        {photos.map((photo, index) => (
          <Col key={index} span={24 / photos.length}>
            {renderImage(photo, index)}
          </Col>
        ))}
      </Row>
    )
  }

  // for photos more than 4, show them in a gallery grid layout
  const renderGrid = (startIndex) => (
    <>
      <Col className={styles.heroImage} span={12}>
        {renderImage(photos[startIndex], startIndex)}
      </Col>
      {photos[startIndex + 1] && (
        <Col span={12}>
          <Row gutter={gutter}>
            {Array.from({ length: 4 }).map(
              (_, i) =>
                photos[startIndex + i + 1] && (
                  <Col key={i} span={12} className={styles.thumbnailImage}>
                    {renderImage(photos[startIndex + i + 1], startIndex + i + 1)}
                  </Col>
                )
            )}
          </Row>
        </Col>
      )}
      {photos[startIndex + 5] && (
        <Col className={styles.heroImage} span={12}>
          {renderImage(photos[startIndex + 5], startIndex + 5)}
        </Col>
      )}
      {photos[startIndex + 6] && (
        <Col className={styles.heroImage} span={12}>
          {renderImage(photos[startIndex + 6], startIndex + 6)}
        </Col>
      )}
      {Array.from({ length: 4 }).map(
        (_, i) =>
          photos[startIndex + 7 + i] && (
            <Col key={i} span={6} className={styles.thumbnailImage}>
              {renderImage(photos[startIndex + 7 + i], startIndex + 7 + i)}
            </Col>
          )
      )}
    </>
  )

  return (
    <Row gutter={gutter}>
      {Array.from({ length: Math.ceil(photos.length / 12) }).map((_, i) => (
        <React.Fragment key={i}>{renderGrid(i * 12)}</React.Fragment>
      ))}
    </Row>
  )
}

Gallery.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      placeholder_url: PropTypes.string
    })
  ).isRequired,
  onImageClick: PropTypes.func
}

export default Gallery
